import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import "./press.css"
import { SideBar } from "./sidebar"
import Img from "gatsby-image"

interface pressProps {
  press: any
}

export const Press: React.FC<pressProps> = ({ press }) => {
  return (
    <section id="blog" className="blog">
      <div className="container" data-aos="fade-up">
        <div className="row g-5">
          <div className="col-lg-8">
            <div className="row gy-4 posts-list">
              {press.map(({ frontmatter }, index) => (
                <div className="col-lg-6" key={index}>
                  <article className="d-flex flex-column">
                    <div className="post-img">
                      <GatsbyImage image={frontmatter.image.childImageSharp.gatsbyImageData} alt={""} />
                    </div>

                    <h2 className="title">
                      <a href={frontmatter.slug}>{frontmatter.title}</a>
                    </h2>

                    <div className="meta-top">
                      <ul>
                        <li className="d-flex align-items-center">
                          <i className="bx bx-user"></i>{" "}
                          <a href={frontmatter.slug}>{frontmatter.author}</a>
                        </li>
                        <li className="d-flex align-items-center">
                          <i className="bx bx-time"></i>{" "}
                          <a href={frontmatter.slug}>
                            <time dateTime={frontmatter.date}>
                              {frontmatter.date}
                            </time>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="content">
                      <p>{frontmatter.shortDescription}</p>
                    </div>

                    <div className="read-more mt-auto align-self-end">
                      <a href={frontmatter.slug}>Read More</a>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
          <SideBar recentPosts={press} />
        </div>
      </div>
    </section>
  )
}
