import React from "react"
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb"
import Layout from "../../components/layout"
import { Press } from "../../components/Press/press"
import Seo from "../../components/seo"
import { graphql, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

const links = [
  { displayName: "Home", link: "/" },
  { displayName: "About", link: "/#about" },
  { displayName: "Contact", link: "/#contact" },
  { displayName: "Press", link: "/press" },
]

type PressPageProps = {
  allMarkdownRemark: {
    nodes: Array<{
      frontmatter: {
        date: string
        shortDescription: string
        slug: string
        title: string
        image: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
      }
    }>
  }
}

const PressPage = ({ data }: PageProps<PressPageProps>) => {
  const { allMarkdownRemark } = data
  const { nodes } = allMarkdownRemark

  return (
    <Layout navigationLinks={links}>
      <Seo title="Press" />
      <main
        className={"mt-5"}
        id={"main"}
        style={{
          background: "url('../images/hero-bg.jpg') top center",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Breadcrumb pageNames={[{name: "Press"}]} title={"Press"} />
        <Press press={nodes} />
      </main>
    </Layout>
  )
}

export default PressPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          date
          shortDescription
          slug
          title
          author
          image {
            childImageSharp {
              gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
